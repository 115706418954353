<template>
  <div>
    <breadcrumbs :title="data.title"/>

    <div class="section section-border">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <h3>{{ data.title }}</h3>
            <img :src="data.image" @error="$event.target.src='https://via.placeholder.com/800x600'" alt=""
                 class="img-responsive img-rounded image-fit">
            <br>
            <div v-html="data.content"/>
          </div>
        </div>
        <div class="spacer-50"></div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Detail",
  components: {
    breadcrumbs: () => import('@/components/layout/Breadcrumb'),
  },
  data() {
    return {
      data: {
        title: null,
        content: null,
        image: null
      },
      accordions: []
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.data = {
        title: null,
        content: null,
        image: null
      };
      this.accordions = [];
      this.isLoading = true;
      try {
        var data = await this.$axios.get('categories/success-stories/articles/' + this.$route.name);
        const response = data.data.data;
        this.data = {
          title: response['title'],
          content: response['content'],
          image: response['image_path']
        };
      } catch (e) {
        console.log(e);
      }
    },
  },
  watch: {
    '$route': function () {
      this.init();
    }
  }
}
</script>

<style scoped>

</style>
